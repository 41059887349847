import React from 'react';
import './App.css';
import './asset/css/fonts.css';
import './asset/css/landingpage.css';
import './asset/css/storepage.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Route, Switch } from "react-router-dom";

import 'onsenui/css/onsenui.css';
import 'onsenui/css/onsen-css-components.css';

// --------------- Import Page ---------------
import Landingpage from './views/LandingPage/index';
import RegisterPage from './views/RegisterPage/index';
import PlayPageDetail from './views/PlayPageDetail/index';


import MemberPage from './views/MemberPage/index';
import WallpaperPage from './views/WallpaperPage/index';
import ComingPage from './views/ComingPage/index';


import StorePage from './views/StorePage';
import Profile from './views/Profile';
import Promotion from './views/Promotion';
import Privilege from './views/Privilege';
import History from './views/History';
import Event from './views/Event';
import EventApp from './views/EventApp';
import LiveGame from './views/LiveGame';

import GamePage from './views/GamePage';
import PlayPage from './views/PlayPage';
import GenTokenKey from './views/GenTokenKey';
import Setting from './views/Setting';


import ViewEvent from './views/ViewEvent';

import BigWin from './views/BigWin';

import SendSMS from './views/SendSMS';
import LoykrathongFlip from './views/LoykrathongFlip';
import LoykrathongJedi from './views/LoykrathongJedi';
import LoykrathongOsaka from './views/LoykrathongOsaka';
import LoykrathongSonic from './views/LoykrathongSonic';


import HeadApp from './component/HeadApp';

function App() {
  return (
    <div>
      <BrowserRouter>
        <Switch>
          <Route path="/" exact component={RegisterPage} />
          <Route path="/register" component={RegisterPage} />
          <Route path="/PlayPageDetail" component={PlayPageDetail} />

          <Route path="/member" component={MemberPage}/>
          <Route path="/wallpaper" component={WallpaperPage}/>
          
          <Route path="/storepage" component={StorePage}/>
          <Route path="/profile" component={Profile}/>
          <Route path="/promotion" component={Promotion}/>
          <Route path="/Privilege" component={Privilege}/>
          <Route path="/history" component={History}/>
          <Route path="/Event" component={Event}/>
          <Route path="/EventApp" component={EventApp}/>
          <Route path="/LiveGame" component={LiveGame}/>


          <Route path="/GamePage" component={GamePage}/>
          <Route path="/PlayPage" component={PlayPage}/>
          <Route path="/GenTokenKey" component={GenTokenKey}/>
          <Route path="/Setting" component={Setting}/>


          <Route path="/BigWin" component={BigWin}/>
          <Route path="/ViewEvent" component={ViewEvent}/>
          <Route path="/SendSMS" component={SendSMS}/>
          <Route path="/LoykrathongFlip" component={LoykrathongFlip}/>
          <Route path="/LoykrathongJedi" component={LoykrathongJedi}/>
          <Route path="/LoykrathongOsaka" component={LoykrathongOsaka}/>
          <Route path="/LoykrathongSonic" component={LoykrathongSonic}/>

          
          <Route path="/ComingPage" component={ComingPage}/>
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
