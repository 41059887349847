import React from 'react';
import { Container } from "./styles";
import { Row,Col,Button,Card} from 'react-bootstrap';
import Form from 'react-bootstrap/Form'
import { config } from "../../configs/constant";

import axios from "axios";


import jwt_decode from "jwt-decode";

import loading from '../../asset/images/neeyai/loading.gif';
import logo from '../../asset/images/rootslot.png';

import swal from 'sweetalert';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye,faHeart } from '@fortawesome/free-solid-svg-icons'
import imggame from '../../asset/images/games/icon/1.png';

import { slide as Menu } from 'react-burger-menu'



import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';


class RegisterPage extends React.Component {
  state = {
    loading:false,
    imageProfile:"",
    imageProfileDef : "https://social-tournaments.s3.eu-central-1.amazonaws.com/wp-content/uploads/2020/07/09115428/Street-Racer%E2%84%A2-Medium-Logo%402x-300x193.png",
    loading:"",
    lookinput:false,
    register:false,
    BankName:"",
    BankNo:"",
    displayname:"",
    email:"",
    validated:false,
    gameList:[],
    listCarousel:[],
    gameType:[],
    lgname:'',
  };

  
  componentDidMount(){


    var url = new URL(window.location.href);
    var typeId = url.searchParams.get("type");
    var id = url.searchParams.get("id");
    var lg = url.searchParams.get("lg").toString();
    this.setState({lgname:lg})


    if(typeId != "0" && typeId != null){
      var filetergame = config.listGame.filter(o=> o.typeId == typeId);
    }
    else{
      filetergame = config.listGame;
    }

    this.setState({
      gameList:filetergame.map(o=> {
          return(
            <div className='game-card'>
            <div className='card-img'>
            <img src={require('../../asset/images/games/icon/'+ o.icon)}/>
            </div>
            <div className='card-detail'>
              <h3>{o.name}</h3>
              <div className='view'>
                <div className='icon'><FontAwesomeIcon icon={faEye} /></div>
                <div className='icon-detail'>{o.view}</div>
              </div>
              <div className='view'>
                <div className='icon'><FontAwesomeIcon icon={faHeart} /></div>
                <div className='icon-detail'>{o.like}</div>
              </div>
              <div id={o.id} className='play-game' onClick={this.ClickGame}>{lg == "th" ? "เล่นกมส์":"PLAY"}</div>
            </div>
          </div>
          )
      })
    })

    this.setState({
      listCarousel:config.listGame.map(o=> {
        return(
          <div>
            <img className="img-c-w100" src={require('../../asset/images/games/image/'+ o.image)}/>
            <div className='detail-game-banner'>
            <div className='flx-div-detail'>
              <div className='img-detail'>
                <img className="img-c-w10" src={require('../../asset/images/games/icon/'+ o.icon)}/>
              </div>
              <div className='text-detail-conner'>
                <div className='text-detail-tile'>{o.name}</div>
                <div className='text-detail'>{o.detail}</div>
              </div>
            </div>
            </div>
          </div>
        )
      })
    })


    this.setState({
      gameType:config.typeGame.map(o=>{


        if(lg == "th"){
          return(
            <a id={o.typeId} className="menu-item" href={"?id="+id+"&type="+o.typeId}>{o.name}</a>
          )
        }
        else if (lg == "en"){
          return(
            <a id={o.typeId} className="menu-item" href={"?id="+id+"&type="+o.typeId}>{o.nameen}</a>
          )
        }
        else{
          return(
            <a id={o.typeId} className="menu-item" href={"?id="+id+"&type="+o.typeId}>{o.name}</a>
          )
        }
      })
    })



    var url = new URL(window.location.href);
    var id = url.searchParams.get("id");


    this.setState({tokenkey:id})

    // this.CallEnterTokenKey(id);

  }



CallRegister = () =>{
  if(this.state.register == true){
    this.setState({
      loading:loading,
      lookinput:true,
      bankList:''
    })
    setTimeout(() => {
      this.setState({loading:true});
      this.props.history.push("/member");
    }, 2000);
  }
}



CallEnterTokenKey = (e) =>{

  axios({
    method: 'get',
    url: (`${config.url}/content/PostRootSlotEnterKey?key=${e}`),
  })
  .then(resp => {
    if(resp.data.status){

      // swal("เปิดใช้งานสำเร็จ", {
      //   icon: "success",
      //   title: "สำเร็จ",
      //   buttons: false,
      //   timer: 2000,
      // });

      // setTimeout(() => {
      //   this.setState({loading:true});
      //   // this.props.history.push("/GamePage");
      // }, 2000);
      return
    }
    else{
      swal("เกิดข้อผิดพลาด", "ลิ้งค์นี้ได้ทำการเปิดใช้งานไปแล้ว หรือ โปรติดต่อผู้ให้บริการ",{ dangerMode: true});
      this.props.history.push("/ComingPage");
      return
    }
  })
  .catch((error) => {
    swal("เกิดข้อผิดพลาด", "โปรติดต่อผู้ให้บริการ",{ dangerMode: true});
    this.props.history.push("/ComingPage");
    return
  });
}


ClickGame = (e) =>{

var id = e.target.id;


  axios({
    method: 'get',
    url: (`${config.url}/content/PostRootSlotEnterKey?key=${this.state.tokenkey}`),
  })
  .then(resp => {
    if(resp.data.status){

      window.location.assign("playPageDetail?id="+id+'&token='+this.state.tokenkey+'&lg='+this.state.lgname)
      return
    }
    else{
      if(this.state.lg == "th"){
        swal("เกิดข้อผิดพลาด", "ลิ้งค์นี้ได้ทำการเปิดใช้งานไปแล้ว หรือ โปรติดต่อผู้ให้บริการ",{ dangerMode: true});
        this.props.history.push("/ComingPage");
        return
      }
      else{
        swal("An error occurred", "This link has already been activated or please contact the service provider.",{ dangerMode: true});
        this.props.history.push("/ComingPage");
        return
      }
    }
  })
  .catch((error) => {
    if(this.state.lg == "th"){
      swal("เกิดข้อผิดพลาด", "โปรติดต่อผู้ให้บริการ",{ dangerMode: true});
      this.props.history.push("/ComingPage");
      return
    }
    else{
      swal("An error has occurred", "Please contact the service provider",{ dangerMode: true});
      this.props.history.push("/ComingPage");
      return
    }
  });
}




inputRegister = (e) =>{

  this.setState({ [e.target.name]: e.target.value });

}


showSettings (event) {
  event.preventDefault();
}


  render() {

    

    const responsive = {
      superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
      },
    };

    

    return (
        <>
        {/* <video autoPlay={true} muted loop id="myVideo">
            <source src={vdo} type="video/mp4"/>
        </video> */}
        <Container>
        <Menu>
        {this.state.gameType}
      </Menu>
        <Carousel responsive={responsive} autoPlaySpeed={3000} autoPlay={true} infinite={true}>
          {/* ----------------- */}
          {this.state.listCarousel}
          {/* ----------------- */}

        </Carousel>
            <div className="app-conten">
                <div className='game-conner'>
                  {this.state.gameList}
                </div>
            </div>
        </Container>
        </>
    );
  }
}

export default (RegisterPage);