import React from 'react';
import { Route , withRouter} from 'react-router-dom';
import { withStyles } from "@material-ui/core/styles";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import EmojiEmotionsOutlinedIcon from '@material-ui/icons/EmojiEmotionsOutlined';
import PermIdentityOutlinedIcon from '@material-ui/icons/PermIdentityOutlined';
import HistoryOutlinedIcon from '@material-ui/icons/HistoryOutlined';
import FavoriteBorderOutlinedIcon from '@material-ui/icons/FavoriteBorderOutlined';
import { Sunglasses } from 'react-bootstrap-icons';

import { config } from "../configs/constant";
import axios from "axios";
import swal from 'sweetalert';


class HeadApp extends React.Component {
  state = {
    value:'Recents',
    userProfile:"",
  };

  componentDidMount(){


  }

  CallEnterTokenKey = (e) =>{

    axios({
      method: 'get',
      url: (`${config.url}/content/PostRootSlotEnterKey?key=${e}`),
    })
    .then(resp => {
      if(resp.data.status){
  
        // swal("เปิดใช้งานสำเร็จ", {
        //   icon: "success",
        //   title: "สำเร็จ",
        //   buttons: false,
        //   timer: 2000,
        // });
  
        // setTimeout(() => {
        //   this.setState({loading:true});
        //   // this.props.history.push("/GamePage");
        // }, 2000);
        return
      }
      else{
        swal("เกิดข้อผิดพลาด", "ลิ้งค์นี้ได้ทำการเปิดใช้งานไปแล้ว หรือ โปรติดต่อผู้ให้บริการ",{ dangerMode: true});
        this.props.history.push("/ComingPage");
        return
      }
    })
    .catch((error) => {
      swal("เกิดข้อผิดพลาด", "โปรติดต่อผู้ให้บริการ",{ dangerMode: true});
      this.props.history.push("/ComingPage");
      return
    });
  }

  render() {
    return (
      <div>

    </div>
    );
  }
}

export default (HeadApp);