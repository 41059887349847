export const config = {
    url : "https://vm-2020-api.azurewebsites.net",
    //url : "https://localhost:5009",
    imgDef : "https://i2.wp.com/www.bengtolcollege.ac.in/wp-content/uploads/2020/07/thesomeday123170900021.jpg?w=450&ssl=1",


    typeGame:[
        {
            typeId:"0",
            name:"ทั้งหมด",
            nameen:"All",
        },
        {
            typeId:"1",
            name:"ตื่นเต้น",
            nameen:"Adventure",
        },
        {
            typeId:"2",
            name:"ใช้ความคิดระดับสูง",
            nameen:"Brain",
        },
        {
            typeId:"3",
            name:"ฝึกสมาธิ",
            nameen:"Focus",
        },
        {
            typeId:"4",
            name:"ความเร็ว",
            nameen:"Sports",
        },
        {
            typeId:"5",
            name:"สายตา",
            nameen:"Vision",
        },
    ],
    

    listGame:[
        {
            id:"01",
            typeId:"1",
            name:"The Angler",
            url:"gameprotal/01",
            icon:"1.png",
            image:"1.png",
            view:"4,569",
            like:"6,986",
            detail:"Beautiful graphics, nice music guarantee hours of exciting game. Easy to install on WordPress",
            carousel:[
                {
                    icon:"1.png",
                    image:"1.png",
                },
                {
                    icon:"1.png",
                    image:"1.png",
                },
                {
                    icon:"1.png",
                    image:"1.png",
                },
                {
                    icon:"1.png",
                    image:"1.png",
                },
                {
                    icon:"1.png",
                    image:"1.png",
                },
            ]
        },
        {
            id:"02",
            typeId:"1",
            name:"Gummy Blocks",
            url:"gameprotal/02",
            icon:"2.png",
            image:"2.jpg",
            view:"5,693",
            like:"4,766",
            detail:"Beautiful graphics, nice music guarantee hours of exciting game. Easy to install on WordPress",
            carousel:[
                {
                    icon:"2.png",
                    image:"2.jpg",
                },
                {
                    icon:"2.png",
                    image:"2.jpg",
                },
                {
                    icon:"2.png",
                    image:"2.jpg",
                },
                {
                    icon:"2.png",
                    image:"2.jpg",
                },
                {
                    icon:"2.png",
                    image:"2.jpg",
                },
            ]
        },
        {
            id:"03",
            typeId:"1",
            name:"Treasure island",
            url:"gameprotal/03",
            icon:"3.png",
            image:"3.png",
            view:"9,893",
            like:"7,636",
            detail:"Beautiful graphics, nice music guarantee hours of exciting game. Easy to install on WordPress",
            carousel:[
                {
                    icon:"3.png",
                    image:"3.png",
                },
                {
                    icon:"3.png",
                    image:"3.png",
                },
                {
                    icon:"3.png",
                    image:"3.png",
                },
                {
                    icon:"3.png",
                    image:"3.png",
                },
                {
                    icon:"3.png",
                    image:"3.png",
                },
            ]
        },
        {
            id:"04",
            typeId:"2",
            name:"Brick Out",
            url:"gameprotal/04",
            icon:"4.png",
            image:"4.jpg",
            view:"6,934",
            like:"4,965",
            detail:"Beautiful graphics, nice music guarantee hours of exciting game. Easy to install on WordPress",
            carousel:[
                {
                    icon:"4.png",
                    image:"4.jpg",
                },
                {
                    icon:"4.png",
                    image:"4.jpg",
                },
                {
                    icon:"4.png",
                    image:"4.jpg",
                },
                {
                    icon:"4.png",
                    image:"4.jpg",
                },
                {
                    icon:"4.png",
                    image:"4.jpg",
                },
            ]
        },
        {
            id:"05",
            typeId:"2",
            name:"Animals Word",
            url:"gameprotal/05",
            icon:"5.png",
            image:"5.jpg",
            view:"3,794",
            like:"4,792",
            detail:"Beautiful graphics, nice music guarantee hours of exciting game. Easy to install on WordPress",
            carousel:[
                {
                    icon:"5.png",
                    image:"5.jpg",
                },
                {
                    icon:"5.png",
                    image:"5.jpg",
                },
                {
                    icon:"5.png",
                    image:"5.jpg",
                },
                {
                    icon:"5.png",
                    image:"5.jpg",
                },
                {
                    icon:"5.png",
                    image:"5.jpg",
                },
            ]
        },
        {
            id:"06",
            typeId:"2",
            name:"Whack 'em All",
            url:"gameprotal/06",
            icon:"6.png",
            image:"6.jpg",
            view:"3,621",
            like:"2,542",
            detail:"Beautiful graphics, nice music guarantee hours of exciting game. Easy to install on WordPress",
            carousel:[
                {
                    icon:"6.png",
                    image:"6.jpg",
                },
                {
                    icon:"6.png",
                    image:"6.jpg",
                },
                {
                    icon:"6.png",
                    image:"6.jpg",
                },
                {
                    icon:"6.png",
                    image:"6.jpg",
                },
                {
                    icon:"6.png",
                    image:"6.jpg",
                },
                {
                    icon:"6.png",
                    image:"6.jpg",
                },
            ]
        },
        {
            id:"07",
            typeId:"3",
            name:"Pinball Space Adventure",
            url:"gameprotal/07",
            icon:"7.png",
            image:"7.jpg",
            view:"5,631",
            like:"4,851",
            detail:"Beautiful graphics, nice music guarantee hours of exciting game. Easy to install on WordPress",
            carousel:[
                {
                    icon:"7.png",
                    image:"7.jpg",
                },
                {
                    icon:"7.png",
                    image:"7.jpg",
                },
                {
                    icon:"7.png",
                    image:"7.jpg",
                },
                {
                    icon:"7.png",
                    image:"7.jpg",
                },
                {
                    icon:"7.png",
                    image:"7.jpg",
                },
                {
                    icon:"7.png",
                    image:"7.jpg",
                },
            ]
        },
        {
            id:"08",
            typeId:"3",
            name:"Battleship War",
            url:"gameprotal/08",
            icon:"8.png",
            image:"8.jpg",
            view:"9,314",
            like:"4,263",
            detail:"Beautiful graphics, nice music guarantee hours of exciting game. Easy to install on WordPress",
            carousel:[
                {
                    icon:"8.png",
                    image:"8.jpg",
                },
                {
                    icon:"8.png",
                    image:"8.jpg",
                },
                {
                    icon:"8.png",
                    image:"8.jpg",
                },
                {
                    icon:"8.png",
                    image:"8.jpg",
                },
                {
                    icon:"8.png",
                    image:"8.jpg",
                },
            ]
        },
        {
            id:"09",
            typeId:"3",
            name:"Mini Golf World",
            url:"gameprotal/09",
            icon:"9.png",
            image:"9.jpg",
            view:"1,263",
            like:"2,564",
            detail:"Beautiful graphics, nice music guarantee hours of exciting game. Easy to install on WordPress",
            carousel:[
                {
                    icon:"9.png",
                    image:"9.jpg",
                },
                {
                    icon:"9.png",
                    image:"9.jpg",
                },
                {
                    icon:"9.png",
                    image:"9.jpg",
                },
                {
                    icon:"9.png",
                    image:"9.jpg",
                },
                {
                    icon:"9.png",
                    image:"9.jpg",
                },
            ]
        },
        {
            id:"10",
            typeId:"4",
            name:"Brick Out",
            url:"gameprotal/10",
            icon:"10.png",
            image:"10.jpg",
            view:"6,315",
            like:"4,321",
            detail:"Beautiful graphics, nice music guarantee hours of exciting game. Easy to install on WordPress",
            carousel:[
                {
                    icon:"10.png",
                    image:"10.jpg",
                },
                {
                    icon:"10.png",
                    image:"10.jpg",
                },
                {
                    icon:"10.png",
                    image:"10.jpg",
                },
                {
                    icon:"10.png",
                    image:"10.jpg",
                },
                {
                    icon:"10.png",
                    image:"10.jpg",
                },
            ]
        },
        {
            id:"11",
            typeId:"4",
            name:"Katana Fruits",
            url:"gameprotal/11",
            icon:"11.png",
            image:"11.jpg",
            view:"7,915",
            like:"8,361",
            detail:"Katana Fruits is a HTML5 Action game. The goal is to cut all the fruits that appear on the screen without dropping them and avoid the bombs.",
            carousel:[
                {
                    icon:"11.png",
                    image:"11.jpg",
                },
                {
                    icon:"11.png",
                    image:"11.jpg",
                },
                {
                    icon:"11.png",
                    image:"11.jpg",
                },
                {
                    icon:"11.png",
                    image:"11.jpg",
                },
                {
                    icon:"11.png",
                    image:"11.jpg",
                },
            ]
        },
        {
            id:"12",
            typeId:"4",
            name:"Indiara and the Skull Gold",
            url:"gameprotal/12",
            icon:"12.png",
            image:"12.jpg",
            view:"5,694",
            like:"4,596",
            detail:"The adventure is starting! Meet and play with Indiara, a girl who loves to collect ancient artifacts.",
            carousel:[
                {
                    icon:"12.png",
                    image:"12.jpg",
                },
                {
                    icon:"12.png",
                    image:"12.jpg",
                },
                {
                    icon:"12.png",
                    image:"12.jpg",
                },
                {
                    icon:"12.png",
                    image:"12.jpg",
                },
                {
                    icon:"12.png",
                    image:"12.jpg",
                },
            ]
        },
        {
            id:"13",
            typeId:"5",
            name:"Duck Shooter",
            url:"gameprotal/13",
            icon:"13.png",
            image:"13.jpg",
            view:"6,521",
            like:"4,531",
            detail:"Duck Shooter is an Arcade HTML5 Game. Move the scope and shot the ducks before they fly away from the screen!",
            carousel:[
                {
                    icon:"13.png",
                    image:"13.jpg",
                },
                {
                    icon:"13.png",
                    image:"13.jpg",
                },
                {
                    icon:"13.png",
                    image:"13.jpg",
                },
                {
                    icon:"13.png",
                    image:"13.jpg",
                },
                {
                    icon:"13.png",
                    image:"13.jpg",
                },
            ]
        },
        {
            id:"14",
            typeId:"5",
            name:"Sudoku Classic",
            url:"gameprotal/14",
            icon:"14.png",
            image:"14.jpg",
            view:"3,625",
            like:"4,562",
            detail:"Sudoku is known and appreciated by users, on phone and tablet. This is the best way to acquire",
            carousel:[
                {
                    icon:"14.png",
                    image:"14.jpg",
                },
                {
                    icon:"14.png",
                    image:"14.jpg",
                },
                {
                    icon:"14.png",
                    image:"14.jpg",
                },
                {
                    icon:"14.png",
                    image:"14.jpg",
                },
                {
                    icon:"14.png",
                    image:"14.jpg",
                },
            ]
        },
        {
            id:"15",
            typeId:"1",
            name:"Easter Memory",
            url:"gameprotal/15",
            icon:"15.png",
            image:"15.jpg",
            view:"3,624",
            like:"2,451",
            detail:"Easter Memory is a HTML5 Puzzle game. Match all identical cards before time runs out!",
            carousel:[
                {
                    icon:"15.png",
                    image:"15.jpg",
                },
                {
                    icon:"15.png",
                    image:"15.jpg",
                },
                {
                    icon:"15.png",
                    image:"15.jpg",
                },
                {
                    icon:"15.png",
                    image:"15.jpg",
                },
                {
                    icon:"15.png",
                    image:"15.jpg",
                },
            ]
        },
        {
            id:"16",
            typeId:"2",
            name:"Greedy Rabbit",
            url:"gameprotal/16",
            icon:"16.png",
            image:"16.jpg",
            view:"9,684",
            like:"7,845",
            detail:"Greedy Rabbit is dynamic and funny arcade game. Find and eat all vegetables and go out from level.",
            carousel:[
                {
                    icon:"16.png",
                    image:"16.jpg",
                },
                {
                    icon:"16.png",
                    image:"16.jpg",
                },
                {
                    icon:"16.png",
                    image:"16.jpg",
                },
                {
                    icon:"16.png",
                    image:"16.jpg",
                },
                {
                    icon:"16.png",
                    image:"16.jpg",
                },
            ]
        },
        {
            id:"17",
            typeId:"3",
            name:"Millionaire 2019",
            url:"gameprotal/17",
            icon:"17.png",
            image:"17.jpg",
            view:"4,652",
            like:"4,621",
            detail:"Beautiful graphics, nice music guarantee hours of exciting game.",
            carousel:[
                {
                    icon:"17.png",
                    image:"17.jpg",
                },
                {
                    icon:"17.png",
                    image:"17.jpg",
                },
                {
                    icon:"17.png",
                    image:"17.jpg",
                },
                {
                    icon:"17.png",
                    image:"17.jpg",
                },
                {
                    icon:"17.png",
                    image:"17.jpg",
                },
            ]
        },
        {
            id:"18",
            typeId:"4",
            name:"Mad Shark",
            url:"gameprotal/18",
            icon:"18.png",
            image:"18.jpg",
            view:"6,352",
            like:"4,851",
            detail:"Mad shark, is a fascinating and beautiful game in which you have to collect fish, stars, life",
            carousel:[
                {
                    icon:"18.png",
                    image:"18.jpg",
                },
                {
                    icon:"18.png",
                    image:"18.jpg",
                },
                {
                    icon:"18.png",
                    image:"18.jpg",
                },
                {
                    icon:"18.png",
                    image:"18.jpg",
                },
                {
                    icon:"18.png",
                    image:"18.jpg",
                },
            ]
        },
        {
            id:"19",
            typeId:"5",
            name:"Frogtastic",
            url:"gameprotal/19",
            icon:"19.png",
            image:"19.jpg",
            view:"7,452",
            like:"9,624",
            detail:"Make combo and destroy all the balls before they reach the dip!",
            carousel:[
                {
                    icon:"19.png",
                    image:"19.jpg",
                },
                {
                    icon:"19.png",
                    image:"19.jpg",
                },
                {
                    icon:"19.png",
                    image:"19.jpg",
                },
                {
                    icon:"19.png",
                    image:"19.jpg",
                },
                {
                    icon:"19.png",
                    image:"19.jpg",
                },
            ]
        },
        {
            id:"20",
            typeId:"1",
            name:"Olaf The Viking",
            url:"gameprotal/20",
            icon:"20.png",
            image:"20.jpg",
            view:"6,954",
            like:"5,947",
            detail:"Olaf the Viking is a HTML5 Arcade Game",
            carousel:[
                {
                    icon:"20.png",
                    image:"20.jpg",
                },
                {
                    icon:"20.png",
                    image:"20.jpg",
                },
                {
                    icon:"20.png",
                    image:"20.jpg",
                },
                {
                    icon:"20.png",
                    image:"20.jpg",
                },
                {
                    icon:"20.png",
                    image:"20.jpg",
                },
            ]
        },
    ],

    liveGame :[
        {
            name:"Pretty Gaming",
            value:"pretty",
            image:"https://sv1.picz.in.th/images/2021/10/11/uf3lIt.png"
        },
        {
            name:"AE Sexy",
            value:"sexy",
            image:"https://sv1.picz.in.th/images/2021/10/11/uf3Aol.png"
        },
        {
            name:"Allbet",
            value:"allbet",
            image:"https://www.img.in.th/images/fe535c88f6c430ba4c5a6de0f5cc5892.png"
        },
        {
            name:"Sa Gaming",
            value:"sa",
            image:"https://www.img.in.th/images/f420357dca2e59dc19c02e8b41977359.png"
        },
        {
            name:"Big Gaming",
            value:"bg",
            image:"https://www.img.in.th/images/d7e13198ee1457ac03f1186bf171d4c6.png"
        },
        {
            name:"Pragmatic Play",
            value:"pragmaticplay",
            image:"https://www.img.in.th/images/e2b876eef40b27dd84548ce6e1895361.png"
        },
        {
            name:"Dream Gaming",
            value:"dreamgame",
            image:"https://www.img.in.th/images/fbac465e2eb92261d3cb7dd90ae0a183.png"
        },
        {
            name:"eBet",
            value:"ebet",
            image:"https://www.img.in.th/images/1d0f1fcc1956586cac4915394c76a1ee.png"
        },
        {
            name:"Asia Gaming",
            value:"ag",
            image:"https://www.img.in.th/images/4419d9e887381a3d1d7aef5175132fa6.png"
        },
        {
            name:"betgames.tv",
            value:"betgame",
            image:"https://www.img.in.th/images/84fc2335386f9ca84f0f14dc606f03d4.png"
        },
        {
            name:"Green Dragon",
            value:"greendragon",
            image:"https://www.img.in.th/images/180bfd40f4382d9e4c13a05ea7ccb8ec.png"
        },
    ],


    headers :{'Authorization': 'Bearer ' + window.localStorage.getItem("_token")},

    ListBank:["-- โปรดเลือกธนาคาร --","ธนาคารแห่งประเทศไทย","ธนาคารกรุงเทพ","ธนาคารกสิกรไทย	","ธนาคารกรุงไทย","ธนาคารทหารไทย","ธนาคารไทยพาณิชย์",
    "ธนาคารกรุงศรีอยุธยา","ธนาคารเกียรตินาคิน","ธนาคารซีไอเอ็มบีไทย","ธนาคารทิสโก้","ธนาคารธนชาต","ธนาคารยูโอบี","ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร","ธนาคารออมสิน","ธนาคารอาคารสงเคราะห์","	ธนาคารอิสลามแห่งประเทศไทย"],

};