import React from 'react';
import { Container } from "./styles";
import { Row,Col,Button,Card} from 'react-bootstrap';
import Form from 'react-bootstrap/Form'
import { config } from "../../configs/constant";

import axios from "axios";


import jwt_decode from "jwt-decode";

import loading from '../../asset/images/neeyai/loading.gif';
import logo from '../../asset/images/rootslot.png';

import swal from 'sweetalert';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye,faHeart, faLaugh } from '@fortawesome/free-solid-svg-icons'

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';


class PlayPageDetail extends React.Component {
  state = {
    loading:false,
    imageProfile:"",
    imageProfileDef : "https://social-tournaments.s3.eu-central-1.amazonaws.com/wp-content/uploads/2020/07/09115428/Street-Racer%E2%84%A2-Medium-Logo%402x-300x193.png",
    loading:"",
    lookinput:false,
    register:false,
    BankName:"",
    BankNo:"",
    displayname:"",
    email:"",
    validated:false,
    gameList:[],
    listCarousel:[],
    name:"",
    image:"",
    url:"",
    icon:"",
    detail:"",
    icon:"",
    tokenkey:"",
    lg:"",
  };

  
  componentDidMount() {

    var url = new URL(window.location.href);
    var root = url.searchParams.get("root");
    var id = url.searchParams.get("id");
    var tokenkey = url.searchParams.get("token");
    var typeId = url.searchParams.get("type");

    var lg = url.searchParams.get("lg").toString();
    this.setState({lg:lg})


    var result = config.listGame.filter(o=> o.id == id);
    this.setState({
      name:result[0].name,
      view:result[0].view,
      like:result[0].like,
      detail:result[0].detail,
      url:result[0].url,
      image:result[0].image,
      id:result[0].id,
      icon:result[0].icon,
      topdetail:[],
    })

    this.setState({
      listCarousel:result[0].carousel.map(o=> {
        return(
          <div>
            <img className="img-c-w100" src={require('../../asset/images/games/image/'+ o.image)}/>
            <div className='detail-game-banner'>
            <div className='flx-div-detail'>
              <div className='img-detail'>
                <img className="img-c-w10" src={require('../../asset/images/games/icon/'+ o.icon)}/>
              </div>
              <div className='text-detail-conner'>
                <div className='text-detail-tile'>{result[0].name}</div>
                <div className='text-detail'>{result[0].detail}</div>
              </div>
            </div>
            </div>
          </div>
        )
      })
    })



    this.setState({
      topdetail:result.map(o=>{
        return(
          <div className='game-card'>
          <div className='card-img'>
          <img src={require('../../asset/images/games/icon/'+o.icon)}/>
          </div>
          <div className='card-detail'>
            <h3>{o.name}</h3>
            <div className='view'>
              <div className='icon'><FontAwesomeIcon icon={faEye} /></div>
              <div className='icon-detail'>{o.view}</div>
            </div>
            <div className='view'>
              <div className='icon'><FontAwesomeIcon icon={faHeart} /></div>
              <div className='icon-detail'>{o.like}</div>
            </div>
            <div className='play-game' id={o.id} onClick={this.ClickGame}>{lg == "th" ? "เล่นเกมส์":"PLAY"}</div>
          </div>
        </div>
        )
      })
    })



    this.setState({tokenkey:tokenkey})

    this.CallEnterTokenKey(tokenkey)

  }



CallRegister = () =>{
  if(this.state.register == true){
    this.setState({
      loading:loading,
      lookinput:true,
      bankList:''
    })
    setTimeout(() => {
      this.setState({loading:true});
      this.props.history.push("/member");
    }, 2000);
  }
}



CallEnterTokenKey = (e) =>{

  axios({
    method: 'get',
    url: (`${config.url}/content/PostRootSlotEnterKey?key=${e}`),
  })
  .then(resp => {
    if(resp.data.status){

      // swal("เปิดใช้งานสำเร็จ", {
      //   icon: "success",
      //   title: "สำเร็จ",
      //   buttons: false,
      //   timer: 2000,
      // });

      // setTimeout(() => {
      //   this.setState({loading:true});
      //   // this.props.history.push("/GamePage");
      // }, 2000);
      return
    }
    else{
      if(this.state.lg == "th"){
        swal("เกิดข้อผิดพลาด", "ลิ้งค์นี้ได้ทำการเปิดใช้งานไปแล้ว หรือ โปรติดต่อผู้ให้บริการ",{ dangerMode: true});
        this.props.history.push("/ComingPage");
        return
      }
      else{
        swal("An error occurred", "This link has already been activated or please contact the service provider.",{ dangerMode: true});
        this.props.history.push("/ComingPage");
        return
      }
    }
  })
  .catch((error) => {
    if(this.state.lg == "th"){
      swal("เกิดข้อผิดพลาด", "โปรติดต่อผู้ให้บริการ",{ dangerMode: true});
      this.props.history.push("/ComingPage");
      return
    }
    else{
      swal("An error has occurred", "Please contact the service provider",{ dangerMode: true});
      this.props.history.push("/ComingPage");
      return
    }
  });
}


inputRegister = (e) =>{

  this.setState({ [e.target.name]: e.target.value });

}




  ClickGame = (e) =>{

    var id = e.target.id;
    
      axios({
        method: 'get',
        url: (`${config.url}/content/PostRootSlotEnterKey?key=${this.state.tokenkey}`),
      })
      .then(resp => {
        if(resp.data.status){
    
          // window.location.assign("gameprotal/"+id+'/?token='+this.state.tokenkey)
          window.sessionStorage.setItem("play",id)
          window.location.assign("playpage")
          return
        }
        else{
          if(this.state.lg == "th"){
            swal("เกิดข้อผิดพลาด", "ลิ้งค์นี้ได้ทำการเปิดใช้งานไปแล้ว หรือ โปรติดต่อผู้ให้บริการ",{ dangerMode: true});
            this.props.history.push("/ComingPage");
            return
          }
          else{
            swal("An error occurred", "This link has already been activated or please contact the service provider.",{ dangerMode: true});
            this.props.history.push("/ComingPage");
            return
          }
        }
      })
      .catch((error) => {
        if(this.state.lg == "th"){
          swal("เกิดข้อผิดพลาด", "โปรติดต่อผู้ให้บริการ",{ dangerMode: true});
          this.props.history.push("/ComingPage");
          return
        }
        else{
          swal("An error has occurred", "Please contact the service provider",{ dangerMode: true});
          this.props.history.push("/ComingPage");
          return
        }
      });
    }




  render() {

    const responsive = {
      superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
      },
    };


    return (
        <>
        {/* <video autoPlay={true} muted loop id="myVideo">
            <source src={vdo} type="video/mp4"/>
        </video> */}
        <Container>
            <div className="app-conten">
                <div className='game-conner'>
                  {this.state.topdetail}
                </div>
            </div>
            <div className='detail line'>
              <samp>{this.state.lg == "th" ? "มีอะไรใหม่":"What's new"}</samp>
              <p>{this.state.detail}</p>
            </div>
            <div className='detail line'>
            <samp>{this.state.lg == "th" ? "ตัวอย่าง":"example"}</samp>
            </div>
          <Carousel responsive={responsive} autoPlaySpeed={3000} autoPlay={true} infinite={true} className="carousel-fix">

          {this.state.listCarousel}

          </Carousel>
        </Container>
        
        </>
    );
  }
}

export default (PlayPageDetail);