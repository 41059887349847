import React from 'react';
import { Container } from "./styles";
import { Row,Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form'
import { config } from "../../configs/constant";

import axios from "axios";


import jwt_decode from "jwt-decode";

import loading from '../../asset/images/neeyai/loading.gif';

import swal from 'sweetalert';


class PlayPage extends React.Component {
  state = {
    loading:false,

  };

  componentDidMount(){



  }




  render() {
    return (
      <>
      <Container>
          <div className="app-conten">
          <iframe src={'/gameprotal/'+window.sessionStorage.getItem("play")} title="ROOTSLOT"></iframe>
          </div>
      </Container>
      </>
  );
  }
}

export default (PlayPage);