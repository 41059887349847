import styled from "styled-components";

export const Container = styled.div`
  background-position: center;

  color: #000000;
  z-index:900;
  .img-c-w100{
    width: 100%;
  }
  .img-c-w10{
      width: 100%;
      margin-right: 5px;
      border-radius: 10px;
    }
  .detail-game-banner{
    width: 100%;
    background: #ffffff;

    padding: 5px;
    border-radius: 0px 0px 10px 10px;
  }
  .text-detail{
    font-size: 16px;
    color: #86868b;
  }
  .text-detail-tile{
    font-size: 20px;
    color: #86868b;
  }
  .flx-div-detail{
    display: flex;
    .img-detail{
      width: 20%;
    }
    .text-detail-conner{
      width: 80%;
      padding-left: 10px;
    }
  }
  .game-conner{
    display: flex;
    flex-wrap: wrap;
  }
  .game-card{
    max-width: 350px;
    margin-top: 20px;
    margin: 10px;
    display: flex;
    background: #fff;
    padding: 10px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    .card-img{
      width: 40%;
      
      img{
        width: 100%;
        border-radius: 20px;
      }
    }
    .card-detail{
      width: 60%;
      padding-left: 10px;
      .play-game{
        width: 100%;
        height: 40px;
        background: #fcdb16;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        border-radius: 10px;
        box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;
      }
      .view{
        display: flex;
        .icon{
          width: 20%;
          color: #999;
        }
        .icon-detail{
          width: 80%;
          font-size: 18px;
        }
      }
    }
  }
  .app-conten {
    width: 98%;
    padding: 10px;
    margin: auto;
    img {
      width: 100%;
    }
    .fix-img {
      max-width: 300px;
    }
    div {
      align-items: center;
      justify-content: center;
    }
    .fix-center {
      max-width: 768px;
      margin:auto;
      text-align: center;
    }
    .fix-text-left{
      text-align: left;
    }
    .fix-loading{
      width: 7%;
    }
    .app-login-group {
      font-size: 20px;
      border-radius: 50px;
      height: 50px;
      position: relative;
      &.btn-line {
        background-color: #00b300;
        color: #ffffff;
        font-size: 1.5rem;
        cursor: pointer;
      }
      &.btn-facebook {
        background-color: #4267b2;
        color: #ffffff;
        font-size: 1.5rem;
        cursor: pointer;
      }
      &.btn-apple {
        background-color: #000000;
        color: #ffffff;
        font-size: 1.5rem;
        cursor: pointer;
      }
      &.btn-login {
        background-color: #ba0001;
        color: #ffffff;
        font-size: 1.5rem;
        cursor: pointer;
      }
    }
    .fix-size-iconline{
        font-size: 25px;
    }
    .app-center-div {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
    }
    .image-profile{
      width: 100% ;
      margin: auto;
      overflow: hidden;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .cover-profile{
      height:250px;
      padding-top: 10px;
      margin-bottom: 15px;
      background-image: url("https://s3.envato.com/files/6768fb83-7185-4103-971b-0dbc61d8395c/inline_image_preview.jpg");
    }
    input {
      border: solid 1px #cccccc;
    }
    h4 {
      margin-top: 5px;
      font-size: 18px;
      color: #7f7f7f;
    }
    h5 {
      margin-top: 5px;
      font-size: 16px;
      color: #ffffff;
    }
    li {
      font-size: 12px !important;
    }
  }

  /* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 10px;
  top: 10px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #ffffff;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #fcdb16;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}
.bm-item-list > a {
  color:#000000;
  font-size: 20px;
  font-weight: 300;
}
.bm-item-list > a:link {
  text-decoration: none;
}
.bm-item-list > a:visited {
  text-decoration: none;
}
.bm-item-list > a:hover {
  text-decoration: none;
}
.bm-item-list > a:active {
  text-decoration: none;
}
/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}
`;
